import { Input, Text } from '@chakra-ui/react';
import { ChangeEvent, useState } from 'react';

import { ROUTES } from 'common-ts';
import { captureException } from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useBoundStore } from '../../store/useBoundStore.js';
import { toaster } from '../../components/ui/toaster.js';
import { Button } from '../../components/ui/button.js';
import { Field } from '../../components/ui/field.js';

const PASSWORD_RESET_TOAST_ID = 'password-reset-toast';

export default function ForgotPassword() {
  const navigate = useNavigate();
  const supabase = useBoundStore((state) => state.supabase);
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);
  const handleResetPassword = () => {
    if (email === '') {
      setIsError(true);
    } else {
      setIsError(false);
      setIsLoading(true);

      supabase.auth
        .resetPasswordForEmail(email, {})
        .then((res) =>
          res.data
            ? /*  removing this toast because the message can be misleading
                  In case, if the user is not registered or deleted, the message will still show email sent successfully 
                  because the endpoint does not respond with an error in this case.
                  Previously, we used to soft delete the user, so the email would be still sent. 
                  But now, we are hard deleting the user, so the email won't be sent and this is handled by supabase.
                  
                  Checking whether user exists or not is susceptible to email enumeration attack hence not a good idea to check whether user exists or not.
                  For more information: https://github.com/supabase/auth-js/issues/590#issuecomment-1632770323
              */
              navigate(ROUTES.AUTH.SIGN_IN.buildPath({}))
            : toaster.create({
                id: PASSWORD_RESET_TOAST_ID,
                title: t('loginView.forgotPassword.toastResponseErrorMessage'),
                type: 'error',
              })
        )
        .catch((e) => {
          captureException(e);
          toaster.create({
            id: PASSWORD_RESET_TOAST_ID,
            title: t('loginView.forgotPassword.toastRequestErrorMessage'),
            type: 'error',
          });
        })
        .finally(() => setIsLoading(false));
    }
  };
  return (
    <div className="flex w-full max-w-md flex-col items-center">
      <div className="flex w-full flex-col items-center space-y-4">
        <Text fontSize={'3xl'} alignSelf={'self-start'}>
          {t('loginView.forgotPassword.title')}
        </Text>
        <Text fontSize={'sm'} color={'gray.500'}>
          {t('loginView.forgotPassword.subtitle')}
        </Text>
      </div>
      <Field
        className="pt-4"
        label="Email"
        invalid={isError}
        errorText={t('loginView.forgotPassword.emptyEmailErrorMessage')}
        colorPalette={'maia-accent'}
      >
        <Input type="email" value={email} onChange={handleEmailChange} />
      </Field>
      <div className="mt-8 flex w-full justify-between">
        <Button
          variant={'outline'}
          onClick={() => navigate(ROUTES.AUTH.SIGN_IN.buildPath({}))}
        >
          {t('loginView.forgotPassword.backToLoginButton')}
        </Button>
        <Button
          variant={'solid'}
          className="bg-maia-accent text-maia-text-light"
          onClick={handleResetPassword}
          loading={isLoading}
        >
          {t('loginView.forgotPassword.resetPasswordButton')}
        </Button>
      </div>
    </div>
  );
}
