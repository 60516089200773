import { Button, useBreakpointValue } from '@chakra-ui/react';
import {
  FileObjectWithStrategy,
  TempFileObject,
} from '../chat/fileSelector/useStorageSelectionStateHandlers';

import { ExtendedBucket } from '../../@types/extendedTypes';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useTranslation } from 'react-i18next';
import {
  FolderBreadCrumbs,
  pathStringFromBreadCrumbs,
} from '@/pages/chat/fileSelector/utils';
import { STORAGE_SELECTION_MAP_PATH_DELIMITER } from 'common-ts';

type ViewFileButtonProps = {
  file: FileObjectWithStrategy | TempFileObject;
  selectedCollection: ExtendedBucket;
  breadCrumbs: FolderBreadCrumbs;
};

function ViewFileButton({
  file,
  selectedCollection,
  breadCrumbs,
}: ViewFileButtonProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);

  const isMobile = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  async function handlePresignedUrl() {
    let fullFilePath = file.name;
    if (breadCrumbs.length > 0) {
      fullFilePath = `${pathStringFromBreadCrumbs({
        breadCrumbs,
        delimiter: STORAGE_SELECTION_MAP_PATH_DELIMITER,
      })}${STORAGE_SELECTION_MAP_PATH_DELIMITER}${file.name}`;
    }
    const { data, error } = await supabase.storage
      .from(selectedCollection.id)
      .createSignedUrl(fullFilePath, 60);
    if (error) {
      console.error('Error generating URL:', error);
    } else if (data.signedUrl) {
      // Mobile browsers are often blocking new tabs, so we need to redirect instead.
      if (isMobile) {
        window.location.href = data.signedUrl;
      } else {
        window.open(data.signedUrl, '_blank');
      }
    }
  }

  return (
    <Button
      className="font-medium"
      size={'sm'}
      colorPalette={'gray'}
      variant={'outline'}
      onClick={handlePresignedUrl}
    >
      {t('fileManagerPanel.viewFileButton')}
    </Button>
  );
}

export default ViewFileButton;
