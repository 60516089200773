import {
  Database,
  MaiaApiRoutes,
  STORAGE_SELECTION_MAP_PATH_DELIMITER,
  StorageFileSelectionMap,
} from 'common-ts';
import { FolderBreadCrumbs, pathStringFromBreadCrumbs } from './utils';
import {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { FileObject } from '@supabase/storage-js';
import { Uploading } from '../../fileManager/FileProgress';
import { captureException } from '@sentry/react';
import { toaster } from '@/components/ui/toaster';
import { useBoundStore } from '../../../store/useBoundStore';
import { useTranslation } from 'react-i18next';

export type FolderObject = {
  objectType: 'folder';
  name: string;
};

export type TempFolderObject = {
  objectType: 'tempFolder';
  name: string;
  status: Uploading;
};

export type FileObjectWithStrategy = FileObject & {
  objectType: 'fileWithStrategy';
  strategy: 'premium' | 'standard' | null;
  numberOfPages: number | null;
  embeddingStatus: Database['public']['Enums']['embedding_status'];
  embeddingFailReason?:
    | Database['public']['Enums']['embedding_fail_reason']
    | null;
};

export type TempFileObject = {
  objectType: 'tempFile';
  name: string;
  type: string;
  status: Uploading;
  id: string;
};

export default function useStorageSelectionStateHandlers(
  isFileSelectable: (file: FileObjectWithStrategy | TempFileObject) => boolean,
  onUpdateSelectedFileMap: (
    updateValue: SetStateAction<StorageFileSelectionMap>
  ) => void,
  selectedCollection: { id: string; name: string }
) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);

  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<
    (FileObjectWithStrategy | TempFileObject)[]
  >([]);
  const [folders, setFolders] = useState<(FolderObject | TempFolderObject)[]>(
    []
  );
  const [breadCrumbs, setBreadCrumbs] = useState<FolderBreadCrumbs>([]);

  /**
   * Each string represents the entire path of a temporary folder
   */
  const temporaryFolders = useRef<string[]>([]);
  /**
   * Path represents the entire path of a file (including its name) that is currently being uploaded.
   * The uploading flag is there to make 'deletion' easier.
   */
  const uploadingFiles = useRef<{
    [id: string]: { type: string; path: string; uploading: boolean };
  }>({});

  useEffect(() => {
    setBreadCrumbs([]);
    setFolders([]);
    setFiles([]);
    setLoading(false);

    fetchFoldersAndFiles(selectedCollection.id, selectedCollection.name, []);
  }, [selectedCollection]);

  /**
   * Wrapper around `setBreadCrumbs`. For navigation, you should use this instead of `setBreadCrumbs` because it synchronously updates the folder contents with the changed breadcrumbs.
   * @param newBreadCrumbs The updated breadCrumbs or a function to update them based on the previous breadCrumbs.
   */
  function handleChangeBreadCrumbs(
    newBreadCrumbs: SetStateAction<FolderBreadCrumbs>
  ) {
    if (typeof newBreadCrumbs === 'function') {
      setBreadCrumbs((prev) => {
        const updatedBreadCrumbs = newBreadCrumbs(prev);

        fetchFoldersAndFiles(
          selectedCollection.id,
          selectedCollection.name,
          updatedBreadCrumbs
        );
        return updatedBreadCrumbs;
      });
    } else {
      setBreadCrumbs(newBreadCrumbs);
      fetchFoldersAndFiles(
        selectedCollection.id,
        selectedCollection.name,
        newBreadCrumbs
      );
    }
  }

  /**
   * Fetch folders and files contained within the specified folder and updates the state accordingly. Only fetches direct children.
   *
   * @param collectionId The uuid (`storage.buckets.id`) of the collection to fetch the files and folders (at the path specified by the breadcrumbs) for.
   * @param collectionName The name of the collection whose id was provided via `collectionId`.
   * @param currentBreadCrumbs The breadcrumbs specifying the currently selected path.
   */
  const fetchFoldersAndFiles = useCallback(
    async (
      collectionId: string,
      collectionName: string,
      currentBreadCrumbs: FolderBreadCrumbs
    ) => {
      setLoading(true);

      const queryPathString =
        pathStringFromBreadCrumbs({
          breadCrumbs: currentBreadCrumbs,
          delimiter: STORAGE_SELECTION_MAP_PATH_DELIMITER,
        }).replace('/', '') || undefined;

      // The typing here is not ideal.
      // Although folders do not explicitly exist as entries in the database, this will return all folders at the given path them as a 'FileObject' with most of the properties being undefined.
      // Other than the faulty typing, this is good for us, because we don't have to implement our hierarchical fetching logic.
      const { data: storageObjectData, error: storageObjectError } =
        await supabase.storage
          .from(collectionId)
          .list(queryPathString, { limit: 1000 });

      if (storageObjectError) {
        captureException(storageObjectError);
        toaster.create({ type: 'error', title: t('general.reloadError') });
        // Continue with state updates -> empty view on error
      }

      const tempFiles: (FileObjectWithStrategy | TempFileObject)[] = [];
      const tempFolders: (TempFolderObject | FolderObject)[] = [];

      if (storageObjectData?.length) {
        const storageObjectIds: string[] = [];
        storageObjectData.forEach((fileOrFolder) => {
          // Folders do not have an id, only a name
          if (fileOrFolder.id) {
            storageObjectIds.push(fileOrFolder.id);
          } else {
            tempFolders.push({
              objectType: 'folder',
              name: fileOrFolder.name,
            });
          }
        });

        const { data: fileViewData, error: fileViewError } = await supabase
          .from('storage_file_view')
          .select(
            'embedding_strategy, number_of_pages, embedding_status, embedding_fail_reason ,id'
          )
          .eq('bucket_id', collectionId);

        if (fileViewError) {
          captureException(fileViewError);
          toaster.create({ type: 'error', title: t('general.reloadError') });
          // Continue with state updates -> empty view on error
        }

        fileViewData?.forEach((fileViewEntry) => {
          const storageObject = storageObjectData.find(
            (object) => object.id === fileViewEntry.id
          );

          if (storageObject) {
            tempFiles.push({
              objectType: 'fileWithStrategy',
              ...storageObject,
              strategy: fileViewEntry.embedding_strategy,
              numberOfPages: fileViewEntry.number_of_pages,
              embeddingStatus: fileViewEntry.embedding_status || 'PENDING',
              embeddingFailReason: fileViewEntry.embedding_fail_reason,
            });
          }
        });
      }

      // Display temporary folders.
      for (const tempFolderPath of temporaryFolders.current) {
        const pathSplit = tempFolderPath.split('/');
        const folderParentPath = pathSplit
          .slice(0, pathSplit.length - 1)
          .join('/');
        const folderName = pathSplit[pathSplit.length - 1];

        if (
          (queryPathString ? `/${queryPathString}` : '') === folderParentPath &&
          folderName
        ) {
          tempFolders.push({
            objectType: 'folder',
            name: folderName,
          });
        }
      }

      // Iterate through each uploading file
      for (const [id, file] of Object.entries(uploadingFiles.current)) {
        // Skip if not uploading
        if (!file.uploading) continue;

        // Normalize path (remove leading slash if present)
        let { path } = file;
        if (path?.startsWith('/')) path = path.slice(1);

        // Adjust path based on queryPathString or skip if not applicable
        if (queryPathString && path.startsWith(`${queryPathString}/`)) {
          path = path.replace(`${queryPathString}/`, '');
        } else if (queryPathString !== undefined) {
          continue;
        }

        // Extract folder and file name
        const splitPath = path.split('/');
        const fileName = splitPath.pop();
        const folderName = splitPath[0];

        // Create a folder if it doesn't exist yet
        if (folderName && !tempFolders.some((f) => f.name === folderName)) {
          tempFolders.push({
            objectType: 'tempFolder',
            name: folderName,
            status: { type: 'UPLOADING' },
          });
        }

        // If there's no folderName, it's a file
        if (fileName && !folderName) {
          tempFiles.push({
            objectType: 'tempFile',
            name: fileName,
            status: { type: 'UPLOADING' },
            type: file.type,
            id,
          });
        }
      }

      handleUpdateCurrentFolderFiles(
        collectionId,
        collectionName,
        tempFiles,
        tempFolders,
        currentBreadCrumbs
      );
      setLoading(false);
    },
    [workspaceId]
  );

  /**
   * Function to refetch the content of the *current* folder.
   * Only use in cases where no navigation is happening (siteId and current folder are not changing).
   * Might produce unexpected results otherwise because of async state updates.
   */
  const refetchCurrentFolderContents = useCallback(async () => {
    fetchFoldersAndFiles(
      selectedCollection.id,
      selectedCollection.name,
      breadCrumbs
    );
  }, [selectedCollection, breadCrumbs, fetchFoldersAndFiles]);

  /**
   * Use when files and folders contained in a folder have been loaded.
   * Sets the files that will be displayed to fileData and the folders that will be displayed to folderData.
   * Takes all necessary steps to correctly update `selectedFileMap`. No matter if the files and folders are loaded for the first time or have been seen before.
   * `FileData` and `folderData` must represent to contents of the current folder (as determined by `currentBreadcrumbs`) in order for the logic surrounding `selectedFileMap` to work correctly.
   *
   * !breadcrumbs are passed as an argument and not taken from state.
   * This is to ensure that the proper path at the time of calling this function is used, instead of possibly out of sync state.
   *
   * @param collectionId The id of the current collection
   * @param collectionName The name of the current collection.
   * @param fileData The files contained in the current folder.
   * @param folderData The folders contained in the current folder.
   * @param currentBreadCrumbs Breadcrumbs representing the path to the current folder.
   */
  function handleUpdateCurrentFolderFiles(
    collectionId: string,
    collectionName: string,
    fileData: (FileObjectWithStrategy | TempFileObject)[],
    folderData: (FolderObject | TempFolderObject)[],
    currentBreadCrumbs: FolderBreadCrumbs
  ) {
    setFiles(fileData);
    setFolders(folderData);

    const folderPath = pathStringFromBreadCrumbs({
      breadCrumbs: currentBreadCrumbs,
      delimiter: STORAGE_SELECTION_MAP_PATH_DELIMITER,
    });

    onUpdateSelectedFileMap((prevSelectedFileMap) => {
      if (!prevSelectedFileMap[collectionId]) {
        prevSelectedFileMap[collectionId] = {
          selected: false,
          folders: {},
          name: collectionName,
        };
      }

      const collectionSelectionInfo = prevSelectedFileMap[collectionId]!;
      const folderSelectionInfo = collectionSelectionInfo.folders[folderPath];

      // Currently open folder folder has not been seen before -> no selection info in state yet
      // Should only happen for root folder. Selection info for other folders is added as soon as they are seen in their parent folder.
      if (!folderSelectionInfo) {
        collectionSelectionInfo.folders[folderPath] = {
          selected: !!collectionSelectionInfo.selected,
          files: Object.fromEntries(
            fileData
              .filter((file) => isFileSelectable(file))
              .map((file) => {
                return [file.id, !!collectionSelectionInfo.selected];
              })
          ),
        };
      }
      // Folder has already been opened OR marked as selected -> update with missing files
      else {
        const updatedFileMap = Object.fromEntries(
          fileData
            .filter((file) => {
              return isFileSelectable(file);
            })
            .map((file) => {
              return [
                file.id,
                folderSelectionInfo.selected === null
                  ? folderSelectionInfo.files[file.id] ?? false
                  : folderSelectionInfo.selected,
              ];
            })
        );

        prevSelectedFileMap[collectionId]!.folders[folderPath] = {
          selected: folderSelectionInfo.selected,
          files: updatedFileMap,
        };
      }

      for (const childFolder of folderData) {
        const childFolderPath = pathStringFromBreadCrumbs({
          breadCrumbs: [
            ...currentBreadCrumbs,
            { id: childFolder.name, name: childFolder.name },
          ],
          delimiter: STORAGE_SELECTION_MAP_PATH_DELIMITER,
        });

        const childFolderInfo =
          prevSelectedFileMap[collectionId]!.folders[childFolderPath];
        // Child folder not seen yet
        if (!childFolderInfo) {
          prevSelectedFileMap[collectionId]!.folders[childFolderPath] = {
            selected:
              !!prevSelectedFileMap[collectionId]!.folders[folderPath]!
                .selected,
            files: {},
          };
        } else {
          prevSelectedFileMap[collectionId]!.folders[
            childFolderPath
          ]!.selected =
            prevSelectedFileMap[collectionId]!.folders[folderPath]?.selected ===
            null
              ? prevSelectedFileMap[collectionId]!.folders[childFolderPath]
                  ?.selected ?? null
              : prevSelectedFileMap[collectionId]!.folders[folderPath]!
                  .selected;
        }
      }

      return { ...prevSelectedFileMap };
    });
  }

  /**
   * Handles the selection / deselection of an entire folder
   * @param currentBreadCrumbs Breadcrumbs representing the path to the current folder. !This is the folder the user is currently seeing the contents of, not the one being selected.
   * @param folderName Name of the folder to be selected.
   * @param collectionId The id of the current collection.
   */
  function handleFolderSelectToggle(
    currentBreadCrumbs: FolderBreadCrumbs,
    folderName: string,
    collectionId: string
  ) {
    const folderPath = pathStringFromBreadCrumbs({
      breadCrumbs: [
        ...currentBreadCrumbs,
        { id: folderName, name: folderName },
      ],
      delimiter: STORAGE_SELECTION_MAP_PATH_DELIMITER,
    });

    onUpdateSelectedFileMap((prevSelectedFileMap) => {
      if (!prevSelectedFileMap[collectionId]?.folders[folderPath]) {
        // This case should not occur in normal use.
        return prevSelectedFileMap;
      }

      // Only set folder itself to selected. Selection state of contained files is handled in case the folder is opened.
      prevSelectedFileMap[collectionId]!.folders[folderPath]!.selected =
        !prevSelectedFileMap[collectionId]!.folders[folderPath]!.selected;

      // Go up the hierarchy to set all parent folders to indeterminate
      // This may lead to folders having the indeterminate state even though all their children are selected
      // But in order to eliminate this inconsistency, we would need to recursively go through all their children and check their selected states, which could have a huge performance impact.
      Object.keys(prevSelectedFileMap[collectionId]!.folders).forEach(
        (pathKey) => {
          if (pathKey !== folderPath && folderPath.startsWith(pathKey)) {
            prevSelectedFileMap[collectionId]!.folders[pathKey]!.selected =
              null;
          }
        }
      );

      // Set site to indeterminate
      prevSelectedFileMap[collectionId]!.selected = null;

      return { ...prevSelectedFileMap };
    });
  }

  /**
   * Handles all state updates necessary to select / deselect a file.
   *
   * @param currentBreadCrumbs Breadcrumbs representing the path to the current folder.
   * @param fileId Id of the file to be selected.
   * @param collectionId Id of the current collection.
   */
  function handleFileSelectToggle(
    currentBreadCrumbs: FolderBreadCrumbs,
    fileId: string,
    collectionId: string
  ) {
    const folderPath = pathStringFromBreadCrumbs({
      breadCrumbs: currentBreadCrumbs,
      delimiter: STORAGE_SELECTION_MAP_PATH_DELIMITER,
    });

    onUpdateSelectedFileMap((prevSelectedFileMap) => {
      if (
        typeof prevSelectedFileMap[collectionId]?.folders[folderPath]?.files[
          fileId
        ] !== 'boolean'
      ) {
        // This case should not occur in normal use.
        return prevSelectedFileMap;
      }

      prevSelectedFileMap[collectionId]!.folders[folderPath]!.files[fileId] =
        !prevSelectedFileMap[collectionId]!.folders[folderPath]!.files[fileId];

      // Go up the hierarchy to set all parent folders to indeterminate
      // See `handleFolderSelectToggle` for caveats
      Object.keys(prevSelectedFileMap[collectionId]!.folders).forEach(
        (pathKey) => {
          if (folderPath.startsWith(pathKey) || folderPath === pathKey) {
            prevSelectedFileMap[collectionId]!.folders[pathKey]!.selected =
              null;
          }
        }
      );

      // Set site to indeterminate
      prevSelectedFileMap[collectionId]!.selected = null;

      return { ...prevSelectedFileMap };
    });
  }

  /**
   * Update the selection map when file search results are displayed.
   * Search results may stem from any folder that may or may not have been visited yet.
   * This function makes sure that the selectedFileMap has all entries necessary to handle selecting and deselecting the search results.
   *
   * @param searchResult The list of files that were found in the search
   * @param collectionId The id of the collection that the found files reside in.
   */
  function handleSearchResultSelectionUpdates({
    searchResult,
    collectionId,
  }: {
    searchResult: MaiaApiRoutes['/files']['/search']['response']['foundFiles'];
    collectionId: string;
  }) {
    onUpdateSelectedFileMap((prevSelectedFileMap) => {
      // Sanity check
      if (!prevSelectedFileMap[collectionId]) {
        return prevSelectedFileMap;
      }

      for (const file of searchResult) {
        const filePath =
          file.path === ''
            ? ''
            : `${STORAGE_SELECTION_MAP_PATH_DELIMITER}${file.path}`;

        const checkedStatus =
          prevSelectedFileMap[collectionId]!.folders[filePath]?.files[
            file.fileId
          ];

        const pathSplit = filePath.split(STORAGE_SELECTION_MAP_PATH_DELIMITER);

        // See if checked status of any parent differs from that of the file, start with direct parent
        // Any parent that does not exist yet in the selectedFileMap will be created.
        // This is important because other functions expect the parents to exist when the checked status of a file changes.
        let highestParentCheckedStatus: boolean | undefined = undefined;
        for (let i = pathSplit.length; i > -1; i--) {
          const currentPath = pathSplit.slice(0, i).join('/');

          if (
            typeof prevSelectedFileMap[collectionId]!.folders[currentPath]
              ?.selected === 'boolean'
          ) {
            highestParentCheckedStatus = prevSelectedFileMap[collectionId]!
              .folders[currentPath]!.selected as boolean;
          } else if (
            prevSelectedFileMap[collectionId]!.folders[currentPath]
              ?.selected === null
          ) {
            // Do nothing
          } else {
            prevSelectedFileMap[collectionId]!.folders[currentPath] = {
              files: {},
              selected: false,
            };
          }
        }

        prevSelectedFileMap[collectionId]!.folders[filePath]!.files[
          file.fileId
        ] = highestParentCheckedStatus ?? checkedStatus ?? false;
      }

      return { ...prevSelectedFileMap };
    });
  }

  /**
   * Handles the navigation to a folder that is a direct child of the currently viewed folder.
   * @param folderId The id of the folder to navigate to
   * @param name The name of the folder to navigate to
   */
  function handleGoToFolder({
    folderId,
    name,
  }: {
    folderId: string;
    name: string;
  }) {
    handleChangeBreadCrumbs((prev) => {
      return [...prev, { id: folderId, name }];
    });
  }

  /**
   * Handles going back in the current folder hierarchy based on the breadcrumbs.
   * @param index The index of the breadcrumb representing the folder to navigate to.
   */
  function handleGoBackInCrumbs(index: number) {
    handleChangeBreadCrumbs((prev) => {
      return prev.slice(0, index + 1);
    });
  }

  /**
   * Performs all state updates necessary to select all folders and files contained within the currently viewed folder.
   *
   * @param currentBreadCrumbs Breadcrumbs representing the path to the current folder.
   * @param collectionId Id of the current collection
   */
  function handleSelectAll(
    currentBreadCrumbs: FolderBreadCrumbs,
    collectionId: string
  ) {
    const folderPath = pathStringFromBreadCrumbs({
      breadCrumbs: currentBreadCrumbs,
      delimiter: STORAGE_SELECTION_MAP_PATH_DELIMITER,
    });

    onUpdateSelectedFileMap((prevSelectedFileMap) => {
      if (!prevSelectedFileMap[collectionId]?.folders[folderPath]) {
        // This case should not occur in normal use.
        return prevSelectedFileMap;
      }

      // Set all entries in the current folder to selected (all contained files)
      Object.keys(
        prevSelectedFileMap[collectionId]!.folders[folderPath]!.files
      ).forEach((key) => {
        prevSelectedFileMap[collectionId]!.folders[folderPath]!.files[key] =
          true;
      });

      // Set the folder itself to selected
      prevSelectedFileMap[collectionId]!.folders[folderPath]!.selected = true;

      // Set collection to true if done at root level, otherwise set to indeterminate
      prevSelectedFileMap[collectionId]!.selected =
        folderPath === '' ? true : null;

      Object.keys(prevSelectedFileMap[collectionId]!.folders).forEach(
        (pathKey) => {
          // Set all parent folders to indeterminate
          // See `handleFolderSelectToggle` for caveats
          if (pathKey !== folderPath && folderPath.startsWith(pathKey)) {
            prevSelectedFileMap[collectionId]!.folders[pathKey]!.selected =
              prevSelectedFileMap[collectionId]!.folders[pathKey]!.selected ===
                null ||
              prevSelectedFileMap[collectionId]!.folders[pathKey]!.selected ===
                false
                ? null
                : true;
          }
          // Set all child folders to selected
          else if (pathKey.startsWith(folderPath)) {
            prevSelectedFileMap[collectionId]!.folders[pathKey]!.selected =
              true;
          }
        }
      );

      return { ...prevSelectedFileMap };
    });
  }

  /**
   * Performs all state updates necessary to deselect all folders and files contained within the currently viewed folder.
   *
   * @param currentBreadCrumbs Breadcrumbs representing the path to the current folder.
   * @param collectionId The id of the current collection
   */
  function handleDeselectAll(
    currentBreadCrumbs: FolderBreadCrumbs,
    collectionId: string
  ) {
    const folderPath = pathStringFromBreadCrumbs({
      breadCrumbs: currentBreadCrumbs,
      delimiter: STORAGE_SELECTION_MAP_PATH_DELIMITER,
    });

    onUpdateSelectedFileMap((prevSelectedFileMap) => {
      if (!prevSelectedFileMap[collectionId]) {
        // This case should not occur in normal use.
        return prevSelectedFileMap;
      }

      // Set all entries in the current folder to not selected (all contained files)
      Object.keys(
        prevSelectedFileMap[collectionId]!.folders[folderPath]!.files
      ).forEach((key) => {
        prevSelectedFileMap[collectionId]!.folders[folderPath]!.files[key] =
          false;
      });

      // Set the folder itself to not selected
      prevSelectedFileMap[collectionId]!.folders[folderPath]!.selected = false;

      // Set collection to false if done at root level, otherwise set to indeterminate
      prevSelectedFileMap[collectionId]!.selected =
        folderPath === '' ? false : null;

      Object.keys(prevSelectedFileMap[collectionId]!.folders).forEach(
        (pathKey) => {
          // Set all parent folders to indeterminate
          // See `handleFolderSelectToggle` for caveats
          if (pathKey !== folderPath && folderPath.startsWith(pathKey)) {
            prevSelectedFileMap[collectionId]!.folders[pathKey]!.selected =
              prevSelectedFileMap[collectionId]!.folders[pathKey]!.selected ===
                null ||
              prevSelectedFileMap[collectionId]!.folders[pathKey]!.selected ===
                true
                ? null
                : false;
          }
          // Set all child folders to not selected
          else if (pathKey.startsWith(folderPath)) {
            prevSelectedFileMap[collectionId]!.folders[pathKey]!.selected =
              false;
          }
        }
      );

      return { ...prevSelectedFileMap };
    });
  }

  /**
   * Performs all state updates necessary to select / deselect an entire collection
   *
   * @param collectionId The id of the collection to be selected
   * @param collecionName the name of the collection to be selected.
   * @param currentlyViewingCollectionid The id of the collection that is currently being traversed
   * @param currentBreadCrumbs Breadcrumbs representing the path to the current folder
   */
  function handleCollectionSelectToggle(
    collectionId: string,
    collectionName: string,
    currentlyViewingCollectionId: string | undefined,
    currentBreadCrumbs: FolderBreadCrumbs
  ) {
    const folderPath = pathStringFromBreadCrumbs({
      breadCrumbs: currentBreadCrumbs,
      delimiter: STORAGE_SELECTION_MAP_PATH_DELIMITER,
    });

    onUpdateSelectedFileMap((prevSelectedFileMap) => {
      let select = true;
      if (!prevSelectedFileMap[collectionId]) {
        prevSelectedFileMap[collectionId] = {
          selected: select,
          folders: {},
          name: collectionName,
        };
      } else {
        select = !prevSelectedFileMap[collectionId]!.selected;
        prevSelectedFileMap[collectionId]!.selected = select;
      }

      if (collectionId === currentlyViewingCollectionId && folderPath) {
        // If user is viewing a folder in the site to be selected
        // Set all entries in the current folder to selected (all contained files)
        Object.keys(
          prevSelectedFileMap[collectionId]!.folders[folderPath]!.files
        ).forEach((key) => {
          prevSelectedFileMap[collectionId]!.folders[folderPath]!.files[key] =
            select;
        });

        // Set the folder itself to selected
        prevSelectedFileMap[collectionId]!.folders[folderPath]!.selected =
          select;
      }

      // Set all folders of the site that are already present in the selectedFileMap to selected
      Object.keys(prevSelectedFileMap[collectionId]!.folders).forEach((key) => {
        if (key !== 'selected') {
          prevSelectedFileMap[collectionId]!.folders[key]!.selected = select;
        }
      });

      return { ...prevSelectedFileMap };
    });
  }

  function handleCreateTemporaryFolder({
    name,
    currentBreadCrumbs,
  }: {
    name: string;
    currentBreadCrumbs: FolderBreadCrumbs;
  }) {
    const folderPath = pathStringFromBreadCrumbs({
      breadCrumbs: [...currentBreadCrumbs, { id: name, name }],
      delimiter: STORAGE_SELECTION_MAP_PATH_DELIMITER,
    });

    if (!temporaryFolders.current.includes(folderPath)) {
      temporaryFolders.current.push(folderPath);
    }
  }

  function handleRemoveTemporaryFolder({ folderPath }: { folderPath: string }) {
    temporaryFolders.current = temporaryFolders.current.filter(
      (path) => path !== folderPath
    );
  }

  function handleAddUploadingFiles({
    files,
    currentBreadCrumbs,
  }: {
    files: TempFileObject[];
    currentBreadCrumbs: FolderBreadCrumbs;
  }) {
    const folderPath = pathStringFromBreadCrumbs({
      breadCrumbs: currentBreadCrumbs,
      delimiter: STORAGE_SELECTION_MAP_PATH_DELIMITER,
    });

    for (const file of files) {
      uploadingFiles.current[file.id] = {
        type: file.type,
        uploading: true,
        path: `${folderPath}/${file.name}`,
      };
    }
  }

  function handleUploadedFilesFinished({ files }: { files: TempFileObject[] }) {
    const tempFolderPathsToRemove: string[] = [];

    for (const file of files) {
      if (uploadingFiles.current[file.id]) {
        uploadingFiles.current[file.id]!.uploading = false;

        const pathSplit = uploadingFiles.current[file.id]!.path.split('/');
        const folderPath = pathSplit.slice(0, pathSplit.length - 1).join('/');

        tempFolderPathsToRemove.push(folderPath);
      }
    }

    temporaryFolders.current = temporaryFolders.current.filter(
      (path) =>
        !tempFolderPathsToRemove.some(
          (pathToRemove) => pathToRemove.includes(path) // Remove the path itself and all parents (They all exist within the uploaded file's path now)
        )
    );
  }

  return {
    files,
    folders,
    loading,
    breadCrumbs,
    handleSelectAll,
    handleGoToFolder,
    handleDeselectAll,
    handleGoBackInCrumbs,
    handleFileSelectToggle,
    handleAddUploadingFiles,
    handleFolderSelectToggle,
    handleCreateTemporaryFolder,
    handleUploadedFilesFinished,
    handleRemoveTemporaryFolder,
    refetchCurrentFolderContents,
    handleCollectionSelectToggle,
    handleSearchResultSelectionUpdates,
  };
}
