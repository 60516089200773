import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';

type DocumentChatMessageGroupProps = {
  className?: string;
  mode: 'NORMAL' | 'POWER';
  isIframe?: boolean;
  children?: ReactNode;
};

function DocumentChatMessageGroup({
  className,
  mode,
  children,
  isIframe = false,
}: DocumentChatMessageGroupProps) {
  const { t } = useTranslation();

  return (
    <Box
      borderColor={
        mode === 'POWER' || isIframe ? 'maia-accent.500' : 'maia-blue-300'
      }
      className={twMerge(
        `bg-maia-blue-100 flex flex-col gap-3 rounded-2xl border px-2 pb-2 pt-0 md:px-5 md:pb-5`,
        className
      )}
    >
      <Box
        bg={mode === 'POWER' || isIframe ? 'maia-accent.500' : 'maia-blue.300'}
        color={
          mode === 'POWER' || isIframe ? 'maia-accent.50' : 'maia-blue.600'
        }
        className={`self-start rounded-b px-3 py-1 text-xs font-medium`}
      >
        {mode === 'POWER' ? t('chat.powerModeQuery') : t('chat.documentQuery')}
      </Box>
      {children}
    </Box>
  );
}

export default DocumentChatMessageGroup;
