import { Separator } from '@chakra-ui/react';
import { faAngleDown, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { Collapsible } from '@chakra-ui/react';
import { ExtendedBucket } from '../../@types/extendedTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListItem from '../../components/ListItem';
import { decodeName } from 'common-ts';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

type CollectionAccordionProps = {
  title: string;
  expanded: boolean;
  totalFiles: number;
  collections: ExtendedBucket[];
  selectedCollection: ExtendedBucket | undefined;
  onExpandToggle: () => void;
  onCollectionSelect: (collection: ExtendedBucket) => void;
};

function CollectionAccordion({
  title,
  expanded,
  totalFiles,
  collections,
  selectedCollection,
  onExpandToggle,
  onCollectionSelect,
}: CollectionAccordionProps) {
  const { t } = useTranslation();

  return (
    <Collapsible.Root open={expanded} onOpenChange={onExpandToggle}>
      <div className="mb-4 flex flex-col gap-4">
        <div className="text-maia-text-dark pt-3 font-medium">{title}</div>
        <div className="flex items-center justify-between">
          <div className="text-chakra-gray-500 text-xs">{`${
            collections.length
          } ${t('fileManagerPanel.collections')} / ${totalFiles} ${t(
            'fileManagerPanel.totalFiles'
          )}`}</div>
          <Collapsible.Trigger asChild>
            <div className="flex cursor-pointer select-none items-center gap-2">
              <div className="text-maia-accent text-xs">
                {expanded ? t('general.collapse') : t('general.expand')}
              </div>
              <FontAwesomeIcon
                icon={faAngleDown}
                className={`${
                  expanded && 'rotate-180'
                } text-maia-accent transition-transform`}
              />
            </div>
          </Collapsible.Trigger>
        </div>
      </div>
      <Collapsible.Content className={`flex flex-shrink-0 flex-col`}>
        {collections.map((collection) => {
          return (
            <ListItem
              key={collection.id}
              className="flex-shrink-0"
              variant="left"
              selected={selectedCollection?.id === collection.id}
              domElementProps={{
                onClick: () => onCollectionSelect(collection),
              }}
            >
              <div className="flex w-full items-center justify-between">
                <div className="flex min-w-0 items-center gap-2">
                  <FontAwesomeIcon
                    icon={faFolder}
                    className="text-chakra-gray-500"
                  />
                  <div className="truncate">
                    {decodeName(collection.display_name)}
                  </div>
                </div>
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className="text-chakra-gray-400"
                />
              </div>
            </ListItem>
          );
        })}
      </Collapsible.Content>
      <Separator />
    </Collapsible.Root>
  );
}

export default CollectionAccordion;
