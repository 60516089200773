// @ts-ignore
import { ReactNode, useMemo, useState } from 'react';
import { faArrowUpFromBracket } from '@fortawesome/pro-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SupportedFileType, MEGABYTE_IN_BYTES } from 'common-ts';
import { useTranslation } from 'react-i18next';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from './ui/dialog';
import { Button } from './ui/button';
import { toaster } from './ui/toaster';
import {
  FileUploadDropzone,
  FileUploadRoot,
  FileUploadTrigger,
} from './ui/file-upload';
import {
  FileUploadFileAcceptDetails,
  FileUploadFileRejectDetails,
} from '@chakra-ui/react';
import { Switch } from './ui/switch';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import {
  faFolderCheck,
  faFolderXmark,
} from '@fortawesome/pro-duotone-svg-icons';

const DEFAULT_MAX_FILES_PER_COLLECTION = 1000;

type GenericUploadModalProps = {
  type: 'fileUpload' | 'glossaryUpload';
  isOpen: boolean;
  children?: ReactNode;
  loading?: string;
  allowMultiUpload: boolean;
  /**
   * MaxFileSize in bytes.
   */
  maxFileSize: number;
  /** There is no specific reason why this couldn't be a type that is not included in `SupportedFileType`
   * for example in the case that this Modal is used to upload something that is completely out of the scope of the embedding service.
   * This type was chosen for convenience as it provides a list of valid mimeTypes.
   * If you ever need to use this component for a mimeType not included here, feel free to create a new type for that.
   */
  acceptedMimeTypes: SupportedFileType[];
  onClose: () => void;
  onFileSelect: (files: FileList) => Promise<void>;
  existingNumberOfFiles?: number;
  maxNumberOfFiles?: number;
  allowFolderUpload?: boolean;
};

function GenericUploadModal({
  type,
  isOpen,
  children,
  loading = undefined,
  allowMultiUpload,
  maxFileSize,
  acceptedMimeTypes,
  onClose,
  onFileSelect,
  existingNumberOfFiles = 0,
  maxNumberOfFiles,
  allowFolderUpload = false,
}: GenericUploadModalProps) {
  const { t } = useTranslation();

  const [isUploading, setIsUploading] = useState(false);
  const [directory, setDirectory] = useState(false);
  const acceptedFileEndings = useMemo(() => {
    return Array.from(
      new Set(
        acceptedMimeTypes.map(
          (mimeType) => supportedFileTypeFileEndingMap[mimeType]
        )
      )
    );
  }, [acceptedMimeTypes]);

  const handleFileAccept = async (details: FileUploadFileAcceptDetails) => {
    if (details.files.length) {
      setIsUploading(true);
      const dataTransfer = new DataTransfer();
      details.files.forEach((file) => dataTransfer.items.add(file));

      await onFileSelect(dataTransfer.files);
      setIsUploading(false);
    }
  };
  const handleFileReject = (details: FileUploadFileRejectDetails) => {
    if (details.files.length) {
      const filteredFiles = details.files.filter(
        // need to filter out .DS_Store files esp for Mac OS, in case of folder uploads
        // as they are hidden files and created upon folder creation that might cause confusion for some users
        (file) => !file.file.name.toLowerCase().includes('ds_store')
      );
      const errors = [...new Set(filteredFiles.flatMap((file) => file.errors))];

      if (errors.length) {
        errors.forEach((error) => {
          switch (error) {
            case 'FILE_INVALID_TYPE':
              toaster.create({
                type: 'error',
                title: `${t('components.genericUploadModal.fileTypeWarning')}${acceptedFileEndings.join(', ')}`,
              });
              return;
            case 'FILE_TOO_LARGE':
              // keeping this error message generic and consistent with file invalid error message.
              // It can be used for both file and glossary uploads
              toaster.create({
                title: `${t('fileManagerPanel.uploadFileSizeError', {
                  limit: `${maxFileSize / MEGABYTE_IN_BYTES}MB`,
                })}`,
                type: 'error',
              });
              return;
            case 'TOO_MANY_FILES':
              switch (type) {
                case 'fileUpload':
                  toaster.create({
                    title: `${t('fileManagerPanel.tooManyFilesError')}`,
                    type: 'error',
                  });
                  break;
                case 'glossaryUpload':
                  toaster.create({
                    type: 'error',
                    title: t(
                      'workspaceSettings.glossarySettings.onlyOneFileAllowed'
                    ),
                  });
                  break;
              }
              return;
            default:
              return;
          }
        });
      }
    }
  };

  return (
    <DialogRoot open={isOpen} onOpenChange={({ open }) => !open && onClose()}>
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('components.genericUploadModal.header')}</DialogTitle>
        </DialogHeader>
        <DialogCloseTrigger />
        <DialogBody>
          <div className="flex flex-col gap-2.5">
            <FileUploadRoot
              maxFileSize={maxFileSize}
              maxFiles={
                allowMultiUpload
                  ? maxNumberOfFiles
                    ? maxNumberOfFiles - existingNumberOfFiles - 1
                    : DEFAULT_MAX_FILES_PER_COLLECTION -
                      existingNumberOfFiles -
                      1
                  : 1
              }
              onFileAccept={handleFileAccept}
              onFileReject={handleFileReject}
              accept={acceptedMimeTypes}
              inputProps={{
                multiple: allowMultiUpload,
              }}
              directory={directory}
              disabled={isUploading}
              className="bg-maia-gray-100 border-maia-border gap-y-0 rounded-xl border border-solid pb-4"
            >
              {directory ? (
                <FileUploadTrigger
                  asChild
                  className="h-36 min-h-max w-full cursor-pointer"
                >
                  <div className="flex flex-col items-center justify-center">
                    <FontAwesomeIcon
                      icon={
                        loading || isUploading
                          ? faSpinnerThird
                          : faArrowUpFromBracket
                      }
                      className={`text-maia-gray-500 text-5xl ${loading || isUploading ? 'animate-spin' : ''}`}
                    />
                    <span className="text-maia-accent mb-1 mt-4 font-semibold">
                      {t('components.genericUploadModal.selectFolder')}
                    </span>
                    {!isUploading && (
                      <div className="text-chakra-gray-500 text-xs">
                        {`${t('components.genericUploadModal.supportedTypesStart')} ${acceptedFileEndings.slice(0, acceptedFileEndings.length - 2).join(', ')}${acceptedFileEndings.length > 1 ? ` ${t('components.genericUploadModal.supportedTypesAnd')} ${acceptedFileEndings.slice(-2, -1)}` : ''}`}
                      </div>
                    )}
                  </div>
                </FileUploadTrigger>
              ) : (
                <FileUploadDropzone
                  className="h-36 min-h-max w-full cursor-pointer border-0 bg-transparent outline-none"
                  label={
                    <div className="font-semibold">
                      {isUploading ? (
                        <div>
                          {t('components.genericUploadModal.waitingMessage')}
                        </div>
                      ) : loading ? (
                        loading
                      ) : (
                        <div>
                          {t('components.genericUploadModal.dndInfo')}{' '}
                          <span className="text-maia-accent">
                            {t('components.genericUploadModal.selectFiles')}
                          </span>
                        </div>
                      )}
                    </div>
                  }
                  description={
                    !isUploading && (
                      <div className="text-chakra-gray-500 text-xs">
                        {`${t('components.genericUploadModal.supportedTypesStart')} ${acceptedFileEndings.slice(0, acceptedFileEndings.length - 2).join(', ')}${acceptedFileEndings.length > 1 ? ` ${t('components.genericUploadModal.supportedTypesAnd')} ${acceptedFileEndings.slice(-2, -1)}` : ''}`}
                      </div>
                    )
                  }
                  icon={
                    <FontAwesomeIcon
                      icon={
                        loading || isUploading
                          ? faSpinnerThird
                          : faArrowUpFromBracket
                      }
                      className={`text-maia-gray-500 text-5xl ${loading || isUploading ? 'animate-spin' : ''}`}
                    />
                  }
                />
              )}
              {/* 
                The file upload can either work in folder upload mode or file upload mode only.
             */}
              {allowFolderUpload && (
                <Switch
                  className="self-center"
                  colorPalette={'maia-accent'}
                  checked={directory}
                  onCheckedChange={({ checked }) => setDirectory(checked)}
                  disabled={isUploading}
                  trackLabel={{
                    on: (
                      <FontAwesomeIcon
                        className="fill-current text-white"
                        icon={faFolderCheck}
                      />
                    ),
                    off: (
                      <FontAwesomeIcon
                        className="text-maia-gray-500 fill-current"
                        icon={faFolderXmark}
                      />
                    ),
                  }}
                >
                  {directory
                    ? t('components.genericUploadModal.disableFolderUpload')
                    : t('components.genericUploadModal.enableFolderUpload')}
                </Switch>
              )}
            </FileUploadRoot>
            {children}
          </div>
        </DialogBody>
        <DialogFooter>
          <div className="flex items-center justify-end gap-3">
            <Button variant="outline" onClick={onClose} disabled={isUploading}>
              {t('general.cancelButton')}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}

const supportedFileTypeFileEndingMap: { [key in SupportedFileType]: string } = {
  'application/pdf': '.pdf',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    '.pptx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    '.docx',
  'text/csv': '.csv',
  'text/plain': '.txt',
  'text/plain;charset=UTF-8': '.txt',
};

export default GenericUploadModal;
