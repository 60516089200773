'use client';

import { ChakraProvider, SystemContext } from '@chakra-ui/react';
import { ColorModeProvider, type ColorModeProviderProps } from './color-mode';
import { defaultSystem } from '../../styles/chakraThemeExtension';

type ProviderProps = {
  system?: SystemContext;
} & ColorModeProviderProps;

export function ChakraContextProvider(props: ProviderProps) {
  const { system, ...rest } = props;
  return (
    <ChakraProvider value={system ?? defaultSystem}>
      <ColorModeProvider {...rest} defaultTheme="light" />
    </ChakraProvider>
  );
}
