import { Database, SupportedFileType, supportedFileTypes } from 'common-ts';

export type FolderBreadCrumbs = { id: string; name: string }[];

/**
 * Constructs a string path from the path given by the breadcrumbs in the form of <delim><parentFolderId><delim><childFolderId><delim><level2ChildFolderId> ...
 *
 * @param breadCrumbs The breadcrumbs to construct the path string from.
 * @param delimiter The delimiter to put between the folder ids. Should be a character or string that can not appear in the folder ids.
 * @returns The string representation of the path given in the breadcrumbs. If the breadcrumbs are empty, returns undefined.
 */
export function pathStringFromBreadCrumbs({
  breadCrumbs,
  delimiter,
}: {
  breadCrumbs: FolderBreadCrumbs;
  delimiter: string;
}) {
  // This is used instead of a simple join for historical reasons
  const folderPath = breadCrumbs.reduce((path, folder) => {
    return `${path}${delimiter}${folder.id}`;
  }, '');

  return folderPath;
}

/**
 * Checks whether a file is selectable for MAIA document chat or search based on its file type and embedding status.
 *
 * @param mimeType The mimetype of the file.
 * @param embeddingStatus The embedding status of the file. Defaults to FAILED.
 * @param csvEnabled Is the csv feature enabled for the workspace that this file is being accessed from?
 * @returns is the file selectable?
 */
export function isFileSelectable({
  mimeType = '',
  embeddingStatus = 'FAILED',
  csvEnabled = false,
}: {
  mimeType: string | undefined | null;
  embeddingStatus:
    | Database['public']['Enums']['embedding_status']
    | undefined
    | null;
  csvEnabled: boolean | undefined | null;
}) {
  return (
    supportedFileTypes.includes(mimeType as SupportedFileType) &&
    (mimeType !== 'text/csv' || (csvEnabled ?? false)) &&
    embeddingStatus === 'FINISHED'
  );
}
