import { Badge, IconButton } from '@chakra-ui/react';
import {
  FileObjectWithStrategy,
  FolderObject,
  TempFileObject,
  TempFolderObject,
} from '../chat/fileSelector/useStorageSelectionStateHandlers.js';
import FileProgress, { FileRowProgressUpdate } from './FileProgress.js';
import {
  FolderBreadCrumbs,
  pathStringFromBreadCrumbs,
} from '../chat/fileSelector/utils.js';
import {
  STORAGE_SELECTION_MAP_PATH_DELIMITER,
  StorageFileSelectionMap,
  cleanUpFileSelectionMap,
  decodeName,
} from 'common-ts';
import { faArrowLeft, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';

import { Button } from '../../components/ui/button.js';
import { Checkbox } from '../../components/ui/checkbox.js';
import { ConfirmDeleteFileModal } from './modals/ConfirmDeleteFileModal';
import { ConfirmNewAnalysisModal } from './modals/ConfirmNewAnalysisModal.js';
import CreateFolderModal from './modals/CreateFolderModal.js';
import { ExtendedBucket } from '../../@types/extendedTypes.js';
import FileIcon from '../../components/FileIcon.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaiaAlertDialogWithUpgradeButton from '../../components/MaiaAlertDialogWithUpgradeButton.js';
import SharepointBreadCrumbs from '../chat/fileSelector/SharepointBreadCrumbs.js';
import SortableTable from '../../components/SortableTable/SortableTable.js';
import { Tooltip } from '../../components/ui/tooltip.js';
import ViewFileButton from './ViewFileButton.js';
import { convertMimeTypeToReadable } from './utils.js';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import { faHourglassEnd } from '@fortawesome/pro-duotone-svg-icons';
import { fetchApi } from '../../utils/useApi.js';
import { toaster } from '@/components/ui/toaster.js';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useTranslation } from 'react-i18next';

type FilesOverviewProps = {
  files: (FileObjectWithStrategy | TempFileObject)[];
  folders: (FolderObject | TempFolderObject)[];
  breadCrumbs: FolderBreadCrumbs;
  selectedFilesMap: StorageFileSelectionMap;
  selectedCollection: ExtendedBucket;
  onBackClick: () => void;
  onFolderClick: (folderName: string) => void;
  onSelectAllClick: () => void;
  onBreadCrumbClick: (index: number) => void;
  onFileCheckboxClick: (fileId: string) => void;
  onFileDeletionStarted: () => void;
  onFolderCheckboxClick: (folderName: string) => void;
  onFolderCreateConfirm: (folderName: string) => void;
  refetchCurrentFolderContents: () => void;
};

/**
 * FilesOverview displays a list or table of files allowing users to manage (delete, view progress) files.
 * It supports both mobile and desktop layouts and integrates with Supabase for backend operations.
 *
 * @param files - Array of files to be displayed.
 * @param selectedCollection - Currently selected collection/bucket.
 * @param onFileListChanged - Callback function that triggers when the list of files changes. e.g. after a file is deleted.
 */
function FilesOverview({
  files,
  folders,
  breadCrumbs,
  selectedFilesMap,
  selectedCollection,
  onBackClick,
  onFolderClick,
  onSelectAllClick,
  onBreadCrumbClick,
  onFileCheckboxClick,
  onFileDeletionStarted,
  onFolderCheckboxClick,
  onFolderCreateConfirm,
  refetchCurrentFolderContents,
}: FilesOverviewProps) {
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const { t } = useTranslation();

  const onClose = () => setIsOpen(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteFileModalOpen, setIsDeleteFileModalOpen] = useState(false);
  const [isNewAnalysisModalOpen, setIsNewAnalysisModalOpen] = useState(false);

  const [filesBeingDeleted, setFilesBeingDeleted] =
    useState<StorageFileSelectionMap>({});
  const [fileDeletionInProgress, setFileDeletionInProgress] = useState(false);

  const [creatingFolder, setCreatingFolder] = useState(false);

  async function handleDeleteFiles(selectedFiles: StorageFileSelectionMap) {
    setFileDeletionInProgress(true);
    setFilesBeingDeleted(selectedFiles);
    onFileDeletionStarted();
    setIsDeleteFileModalOpen(false);

    const cleanedSelectionMap = cleanUpFileSelectionMap(
      selectedFiles,
      STORAGE_SELECTION_MAP_PATH_DELIMITER
    );

    const res = await fetchApi(supabase, '/files', '/delete_files', {
      method: 'POST',
      workspaceId,
      fileSelectionMap: cleanedSelectionMap,
    });

    if (!res.success) {
      toaster.create({ type: 'error', title: t('general.tryAgainError') });
    }

    setFileDeletionInProgress(false);
    refetchCurrentFolderContents();
  }

  /**
   * Issues new Analysis for files which are in filesToBeChanged.
   */
  const newAnalysis = async () => {
    setIsNewAnalysisModalOpen(false);
    await fetchApi(supabase, '/embeddings', '/embed_premium', {
      fileSelectionMap: selectedFilesMap,
      method: 'POST',
      workspaceId,
    });
  };

  /**
   * Get the files that are in progress.
   */
  async function getFilesInProgress() {
    for (const f of files) {
      if (!(f.objectType === 'fileWithStrategy')) {
        return false;
      }

      if (f.embeddingStatus === 'ONGOING' || f.embeddingStatus === 'PENDING') {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    getFilesInProgress();
  }, [selectedCollection]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const cancel = await getFilesInProgress();
      if (cancel) {
        clearInterval(interval);
      } else {
        refetchCurrentFolderContents();
      }
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [files]);

  const HeaderButtons = (
    <div className="flex w-[calc(100%-4rem)] flex-wrap gap-2 py-[10px] font-medium">
      <Button
        className="bg-white font-medium"
        variant={'outline'}
        size={'sm'}
        onClick={onSelectAllClick}
      >
        <Checkbox
          colorPalette="maia-purple"
          size={'md'}
          className="hover:bg-maia-purple-50 border-maia-border pointer-events-none rounded pr-2"
          checked={
            selectedFilesMap[selectedCollection.id]?.folders[
              pathStringFromBreadCrumbs({
                breadCrumbs,
                delimiter: STORAGE_SELECTION_MAP_PATH_DELIMITER,
              })
            ]?.selected ?? false
          }
        />
        {selectedFilesMap[selectedCollection.id]?.folders[
          pathStringFromBreadCrumbs({
            breadCrumbs,
            delimiter: STORAGE_SELECTION_MAP_PATH_DELIMITER,
          })
        ]?.selected
          ? t('general.deselectAll')
          : t('general.selectAll')}
      </Button>
      <Tooltip
        content={
          selectedCollection.access_type === 'VIEW'
            ? t('fileManagerPanel.tooltips.noEditor')
            : t('fileManagerPanel.tooltips.runAda')
        }
        openDelay={500}
      >
        <Button
          className="bg-white font-medium"
          variant={'outline'}
          size={'sm'}
          disabled={
            selectedFilesMap[selectedCollection.id]?.selected === false ||
            selectedCollection.access_type === 'VIEW'
          }
          onClick={() => {
            setIsNewAnalysisModalOpen(true);
          }}
        >
          {t('fileManagerPanel.useAda')}
        </Button>
      </Tooltip>
      <Tooltip
        content={
          selectedCollection.access_type === 'VIEW'
            ? t('fileManagerPanel.tooltips.noEditor')
            : t('fileManagerPanel.tooltips.delete')
        }
        openDelay={500}
      >
        <Button
          className="bg-maia-support-red text-maia-text-light font-medium hover:bg-red-700"
          variant={'outline'}
          size={'sm'}
          disabled={
            selectedFilesMap[selectedCollection.id]?.selected === false ||
            selectedCollection.access_type === 'VIEW'
          }
          onClick={() => {
            setIsDeleteFileModalOpen(true);
          }}
        >
          {t('general.deleteButton')}
        </Button>
      </Tooltip>
      <Button
        variant="outline"
        onClick={() => {
          setCreatingFolder(true);
        }}
      >
        <FontAwesomeIcon className="text-maia-accent" icon={faPlus} />
        {t('fileManagerPanel.createFolderButton')}
      </Button>
    </div>
  );

  return (
    <div className="flex grow flex-col overflow-hidden">
      {HeaderButtons}

      <div className="flex items-center gap-2">
        <IconButton
          disabled={breadCrumbs.length === 0}
          aria-label="back"
          variant={'ghost'}
          onClick={() => {
            onBackClick();
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </IconButton>
        <div className="min-w-0 flex-grow-0 overflow-auto">
          <SharepointBreadCrumbs
            folders={breadCrumbs}
            onFolderSelect={onBreadCrumbClick}
          />
        </div>
      </div>
      <MaiaAlertDialogWithUpgradeButton
        isOpen={isOpen}
        onClose={onClose}
        AlertDialogHeaderText={t('fileManagerPanel.storageLimitReached')}
        AlertDialogBodyText={t('fileManagerPanel.storageLimitReachedBody')}
      />
      <SortableTable<
        | FolderObject
        | FileObjectWithStrategy
        | TempFileObject
        | TempFolderObject
      >
        variant="line"
        objects={[...files, ...folders]}
        rowsSelectable={{
          onSelectionChange: (fileOrFolder) => {
            if (
              fileOrFolder.objectType === 'folder' ||
              fileOrFolder.objectType === 'tempFolder'
            ) {
              onFolderCheckboxClick(fileOrFolder.name);
            } else if (fileOrFolder.objectType === 'fileWithStrategy') {
              onFileCheckboxClick(fileOrFolder.id);
            }
          },
          rowSelected: (fileOrFolder) => {
            if (
              fileOrFolder.objectType === 'folder' ||
              fileOrFolder.objectType === 'tempFolder'
            ) {
              const path = pathStringFromBreadCrumbs({
                breadCrumbs: [
                  ...breadCrumbs,
                  { id: fileOrFolder.name, name: fileOrFolder.name },
                ],
                delimiter: STORAGE_SELECTION_MAP_PATH_DELIMITER,
              });
              return selectedFilesMap[selectedCollection.id]?.folders[path]
                ?.selected;
            } else if (fileOrFolder.objectType === 'fileWithStrategy') {
              const path = pathStringFromBreadCrumbs({
                breadCrumbs,
                delimiter: STORAGE_SELECTION_MAP_PATH_DELIMITER,
              });
              return selectedFilesMap[selectedCollection.id]?.folders[path]
                ?.files[fileOrFolder.id];
            } else {
              return false;
            }
          },
          rowCheckBoxDisabled: (fileOrFolder) => {
            if (
              fileOrFolder.objectType === 'folder' ||
              fileOrFolder.objectType === 'tempFolder'
            ) {
              return false;
            }
            const path = pathStringFromBreadCrumbs({
              breadCrumbs,
              delimiter: STORAGE_SELECTION_MAP_PATH_DELIMITER,
            });
            return (
              filesBeingDeleted[selectedCollection.id]?.folders[path]?.files[
                fileOrFolder.id
              ] ?? false
            );
          },
        }}
        customColumns={{
          icon: {
            title: '',
            cellValue: (fileOrFolder) => {
              return fileOrFolder.objectType === 'fileWithStrategy'
                ? convertMimeTypeToReadable(fileOrFolder.metadata.mimetype)
                : fileOrFolder.objectType === 'tempFile'
                  ? fileOrFolder.type
                  : t('fileManagerPanel.folder');
            },
            cellContentRender: (fileOrFolder) => {
              if (
                fileOrFolder.objectType === 'folder' ||
                fileOrFolder.objectType === 'tempFolder'
              ) {
                return (
                  <FontAwesomeIcon
                    icon={faFolder}
                    className="text-md text-chakra-gray-400"
                  />
                );
              } else if (fileOrFolder.objectType === 'fileWithStrategy') {
                return (
                  <FileIcon
                    mimeType={fileOrFolder.metadata.mimetype}
                    className="text-md text-chakra-gray-400"
                  />
                );
              } else {
                return (
                  <FileIcon
                    mimeType={fileOrFolder.type}
                    className="text-md text-chakra-gray-400"
                  />
                );
              }
            },
            shouldSort: false,
            cellClassName: (fileOrFolder) =>
              fileOrFolder.objectType === 'folder' ||
              fileOrFolder.objectType === 'tempFolder'
                ? 'cursor-pointer'
                : '',
          },
          name: {
            title: t('fileManagerPanel.tableHeaderName'),
            cellValue: (fileOrFolder) => decodeName(fileOrFolder.name),
            cellContentRender: (fileOrFolder) => (
              <span className="max-w-[228px] whitespace-pre-wrap">
                {decodeName(fileOrFolder.name)}
              </span>
            ),
            cellClassName: (fileOrFolder) =>
              fileOrFolder.objectType === 'folder' ||
              fileOrFolder.objectType === 'tempFolder'
                ? 'cursor-pointer'
                : '',
          },
          format: {
            title: t('fileManagerPanel.tableHeaderFormat'),
            cellValue: (fileOrFolder) => {
              return fileOrFolder.objectType === 'fileWithStrategy'
                ? convertMimeTypeToReadable(fileOrFolder.metadata.mimetype)
                : fileOrFolder.objectType === 'tempFile'
                  ? fileOrFolder.type
                  : t('fileManagerPanel.folder');
            },
            cellClassName: (fileOrFolder) =>
              fileOrFolder.objectType === 'folder' ||
              fileOrFolder.objectType === 'tempFolder'
                ? 'cursor-pointer'
                : '',
          },
          uploaded: {
            title: t('fileManagerPanel.tableHeaderUploaded'),
            cellValue: (fileOrFolder) =>
              fileOrFolder.objectType === 'fileWithStrategy'
                ? fileOrFolder.created_at
                : undefined,
            cellContentRender: (fileOrFolder) =>
              fileOrFolder.objectType === 'fileWithStrategy' ? (
                new Date(fileOrFolder.created_at).toLocaleTimeString([], {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                })
              ) : fileOrFolder.objectType === 'tempFile' ? (
                <span className="w-full text-center text-blue-600">
                  <FontAwesomeIcon
                    className="fill-current"
                    icon={faHourglassEnd}
                  />
                </span>
              ) : null,
            cellClassName: (fileOrFolder) =>
              fileOrFolder.objectType === 'folder' ||
              fileOrFolder.objectType === 'tempFolder'
                ? 'cursor-pointer'
                : '',
          },
          analytics: {
            title: t('fileManagerPanel.tableHeaderAnalytic'),
            cellValue: (fileOrFolder) =>
              fileOrFolder.objectType === 'fileWithStrategy'
                ? fileOrFolder.strategy === 'premium'
                  ? 2
                  : 1
                : 0,
            cellContentRender: (fileOrFolder) =>
              fileOrFolder.objectType === 'folder' ||
              fileOrFolder.objectType === 'tempFolder' ? (
                <div />
              ) : (
                <Badge
                  variant={'solid'}
                  className={`${
                    fileOrFolder.objectType === 'fileWithStrategy' &&
                    fileOrFolder.strategy === 'premium'
                      ? 'bg-maia-accent'
                      : 'bg-chakra-gray-400'
                  } text-maia-text-light`}
                >
                  {fileOrFolder.objectType === 'fileWithStrategy' &&
                  fileOrFolder.strategy === 'premium'
                    ? 'ADVANCED'
                    : 'STANDARD'}
                </Badge>
              ),
            cellClassName: (fileOrFolder) =>
              fileOrFolder.objectType === 'folder' ||
              fileOrFolder.objectType === 'tempFolder'
                ? 'cursor-pointer'
                : '',
          },
          status: {
            title: t('fileManagerPanel.tableHeaderStatus'),
            cellValue: () => 1,
            cellContentRender: (fileOrFolder) => {
              if (fileOrFolder.objectType === 'folder') {
                return <div />;
              } else if (fileOrFolder.objectType === 'tempFolder') {
                return (
                  <FileProgress
                    progressUpdate={{
                      status: fileOrFolder.status,
                    }}
                    fileType=""
                  />
                );
              }

              let fileProgressUpdate: FileRowProgressUpdate =
                fileOrFolder.objectType === 'fileWithStrategy'
                  ? {
                      status: { type: fileOrFolder.embeddingStatus },
                      failReason: fileOrFolder.embeddingFailReason,
                    }
                  : { status: fileOrFolder.status };

              const path = pathStringFromBreadCrumbs({
                breadCrumbs,
                delimiter: STORAGE_SELECTION_MAP_PATH_DELIMITER,
              });
              if (
                filesBeingDeleted[selectedCollection.id]?.folders[path]?.files[
                  fileOrFolder.id
                ]
              ) {
                fileProgressUpdate = {
                  status: { type: 'BEING_DELETED' },
                };
              }

              return (
                <FileProgress
                  progressUpdate={fileProgressUpdate}
                  fileType={
                    'metadata' in fileOrFolder
                      ? convertMimeTypeToReadable(
                          fileOrFolder.metadata.mimetype
                        )
                      : fileOrFolder.type
                  }
                />
              );
            },
            cellClassName: (fileOrFolder) =>
              fileOrFolder.objectType === 'folder' ||
              fileOrFolder.objectType === 'tempFolder'
                ? 'cursor-pointer'
                : '',
          },
          preview: {
            title: '',
            cellValue: () => undefined,
            cellContentRender: (fileOrFolder) =>
              fileOrFolder.objectType === 'folder' ||
              fileOrFolder.objectType === 'tempFolder' ||
              fileOrFolder.objectType === 'tempFile' ? null : (
                <ViewFileButton
                  file={fileOrFolder}
                  selectedCollection={selectedCollection}
                  breadCrumbs={breadCrumbs}
                />
              ),
            columnClassName: 'w-40',
            shouldSort: false,
            cellClassName: (fileOrFolder) =>
              fileOrFolder.objectType === 'folder' ||
              fileOrFolder.objectType === 'tempFolder'
                ? 'cursor-pointer'
                : '',
          },
        }}
        onRowClick={(fileOrFolder) => {
          if (
            fileOrFolder.objectType === 'folder' ||
            fileOrFolder.objectType === 'tempFolder'
          ) {
            onFolderClick?.(fileOrFolder.name);
          }
        }}
      />
      <CreateFolderModal
        isOpen={creatingFolder}
        onClose={() => {
          setCreatingFolder(false);
        }}
        onConfirm={(folderName) => {
          onFolderCreateConfirm(folderName);
          setCreatingFolder(false);
        }}
        existingFolderNames={folders.map((folder) => folder.name)}
      />
      <ConfirmNewAnalysisModal
        isOpen={isNewAnalysisModalOpen}
        onConfirm={async () => {
          await newAnalysis();
          refetchCurrentFolderContents();
        }}
        onClose={() => {
          setIsNewAnalysisModalOpen(false);
        }}
        fileSelectionMap={selectedFilesMap}
      />
      <ConfirmDeleteFileModal
        isOpen={isDeleteFileModalOpen}
        onConfirm={() => {
          handleDeleteFiles(selectedFilesMap);
        }}
        onClose={() => {
          setIsDeleteFileModalOpen(false);
        }}
        confirmButtonLoading={fileDeletionInProgress}
      />
    </div>
  );
}

export default FilesOverview;
