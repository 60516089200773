import {
  Database,
  STORAGE_SELECTION_MAP_PATH_DELIMITER,
  StorageFileSelectionMap,
} from 'common-ts';
import GenericSelectableFileAndFolderList, {
  BasicFileInfo,
  BasicFolderInfo,
} from './GenericSelectableFileAndFolderList.js';
import { SetStateAction, useMemo } from 'react';
import { isFileSelectable, pathStringFromBreadCrumbs } from './utils.js';

import SelectableFileSearchResultList from './SelectableFileSearchResultList.js';
import { useBoundStore } from '@/store/useBoundStore.js';
import useStorageSelectionStateHandlers from './useStorageSelectionStateHandlers.js';

export type FileWithStatus = {
  embeddingStatus: Database['public']['Enums']['embedding_status'];
  fileInfoId: string;
  name: string;
};

type SelectableCollectionFileListProps = {
  className?: string;
  totalFiles: number;
  searchTerm: string;
  selectedFileMap: StorageFileSelectionMap;
  selectedCollection: { name: string; id: string };
  onBackFromRootClick: () => void;
  onUpdateSelectedFileMap: (
    updateValue: SetStateAction<StorageFileSelectionMap>
  ) => void;
};

function SelectableCollectionFileList({
  className,
  totalFiles,
  searchTerm,
  selectedFileMap,
  selectedCollection,
  onBackFromRootClick,
  onUpdateSelectedFileMap,
}: SelectableCollectionFileListProps) {
  const csv = useBoundStore((state) => state.featureFlags?.csv);

  const {
    files,
    folders,
    loading,
    breadCrumbs,
    handleSelectAll,
    handleGoToFolder,
    handleDeselectAll,
    handleGoBackInCrumbs,
    handleFileSelectToggle,
    handleFolderSelectToggle,
    refetchCurrentFolderContents,
    handleSearchResultSelectionUpdates,
  } = useStorageSelectionStateHandlers(
    (file) => {
      if (file.objectType === 'tempFile') {
        return false;
      } else {
        return isFileSelectable({
          csvEnabled: csv,
          embeddingStatus: file.embeddingStatus,
          mimeType: file.metadata.mimetype,
        });
      }
    },
    onUpdateSelectedFileMap,
    selectedCollection
  );

  const fileList: BasicFileInfo[] = useMemo(() => {
    return files.map((file) => {
      return {
        embeddingStatus:
          file.objectType === 'fileWithStrategy'
            ? file.embeddingStatus
            : 'PENDING',
        id: file.id,
        mimeType:
          file.objectType === 'fileWithStrategy'
            ? file.metadata.mimetype
            : file.type,
        name: file.name,
      };
    });
  }, [files]);

  const folderList: BasicFolderInfo[] = useMemo(() => {
    return folders.map((folder) => {
      return {
        id: folder.name,
        name: folder.name,
      };
    });
  }, [folders]);

  return searchTerm ? (
    <SelectableFileSearchResultList
      className={className}
      selectedFileMap={selectedFileMap}
      collectionId={selectedCollection.id}
      searchTerm={searchTerm}
      onFileCheckboxClick={(fileId, filePath) => {
        const pathSplit =
          filePath === ''
            ? []
            : filePath.split(STORAGE_SELECTION_MAP_PATH_DELIMITER);
        handleFileSelectToggle(
          pathSplit.map((folderName) => {
            return { id: folderName, name: folderName };
          }),
          fileId,
          selectedCollection.id
        );
      }}
      onSearchResultsLoaded={(searchResult) => {
        handleSearchResultSelectionUpdates({
          searchResult,
          collectionId: selectedCollection.id,
        });
      }}
    />
  ) : (
    <GenericSelectableFileAndFolderList
      className={className}
      hideCounts
      folderMapPathFromBreadCrumbs={(breadCrumbs) =>
        pathStringFromBreadCrumbs({
          breadCrumbs,
          delimiter: STORAGE_SELECTION_MAP_PATH_DELIMITER,
        })
      }
      onRefreshClick={refetchCurrentFolderContents}
      files={fileList}
      folders={folderList}
      breadCrumbs={breadCrumbs}
      overallFileCount={totalFiles}
      loading={loading}
      onBackClick={() => {
        if (breadCrumbs.length) {
          handleGoBackInCrumbs(breadCrumbs.length - 2);
        } else {
          onBackFromRootClick();
        }
      }}
      onDeselectAllClick={() => {
        handleDeselectAll(breadCrumbs, selectedCollection.id);
      }}
      onFileCheckboxClick={(fileId) => {
        handleFileSelectToggle(breadCrumbs, fileId, selectedCollection.id);
      }}
      onFolderCheckboxClick={(folderId) => {
        handleFolderSelectToggle(breadCrumbs, folderId, selectedCollection.id);
      }}
      onFolderClick={({ folderId, name }) => {
        handleGoToFolder({ folderId, name });
      }}
      onSelectAllClick={() => {
        handleSelectAll(breadCrumbs, selectedCollection.id);
      }}
      onSelectFolderInCrumbs={(index) => {
        handleGoBackInCrumbs(index);
      }}
      selectedFileMap={selectedFileMap[selectedCollection.id]?.folders ?? {}}
    />
  );
}

export default SelectableCollectionFileList;
