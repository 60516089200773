import { ProgressBar, ProgressRoot } from '@/components/ui/progress';

import { Text } from '@chakra-ui/react';
import { t } from 'i18next';
import { twMerge } from 'tailwind-merge';

type MessageLimitProps = {
  className?: string;
  sentMessages: number;
  maxMessages: number | null;
};

export default function MessageLimit({
  className,
  sentMessages,
  maxMessages,
}: MessageLimitProps) {
  if (maxMessages === null || sentMessages / maxMessages < 0.8) {
    return null;
  }

  let progressBarColor = 'blue';

  if (maxMessages - sentMessages <= 10) {
    progressBarColor = 'orange';
  }

  if (sentMessages >= maxMessages) {
    progressBarColor = 'red';
  }

  return (
    <div className={twMerge('flex flex-col items-center', className)}>
      <ProgressRoot
        colorPalette={progressBarColor}
        value={
          maxMessages === 0
            ? 0
            : Math.min((sentMessages / maxMessages) * 100, 100)
        }
        size={'xs'}
        width={'320px'}
      >
        <ProgressBar />
      </ProgressRoot>
      <Text
        marginTop={'1'}
        textAlign={'center'}
        fontSize="xs"
        color={'gray.600'}
        fontWeight="normal"
      >
        {`${sentMessages} / ${maxMessages} ${t('searchPanel.requestsMonth')} `}
      </Text>
    </div>
  );
}
