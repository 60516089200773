import { Separator, Text } from '@chakra-ui/react';
import {
  faDatabase,
  faMessage,
  faSliders,
} from '@fortawesome/pro-regular-svg-icons';

import AccessControl from '../AccessControl.js';
import ChangeLanguage from '../ChangeLanguage.js';
import DisabledReactRouterLink from './DisabledReactRouterLink.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ListItem from '../ListItem.js';
import LogoLettering from '../Logo/LogoLettering.js';
import { ROUTES } from 'common-ts';
import { ReactNode } from 'react';
import WorkspaceSwitcher from './WorkspaceSwitcher.js';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useTranslation } from 'react-i18next';

type MaiaMenuPanelProps = {
  children?: ReactNode;
  selectedNavigation: keyof typeof ROUTES.HOME.$;
};

function MaiaMenuPanel({ children, selectedNavigation }: MaiaMenuPanelProps) {
  const { t } = useTranslation();
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const workspaceInactive = useBoundStore((state) => !!state.workspaceInactive);

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="flex min-h-0 flex-grow flex-col">
        {/* ------------------------------------------------ User info ------------------------------------------------ */}
        <WorkspaceSwitcher className="hidden md:flex" />
        <Separator />
        {/* ------------------------------------------------ Navigation ------------------------------------------------ */}
        <div className="flex flex-col gap-2 px-4 py-3">
          <AccessControl
            allowedLicenses={['BASIC', 'PROFESSIONAL', 'ENTERPRISE']}
          >
            <DisabledReactRouterLink
              to={ROUTES.HOME.CHAT.buildPath({
                workspaceId,
              })}
              isDisabled={workspaceInactive}
            >
              <ListItem
                selected={selectedNavigation === 'CHAT'}
                className="gap-2"
                bordered
                isDisabled={workspaceInactive}
              >
                <FontAwesomeIcon
                  icon={faMessage}
                  className={`text-maia-purple-500 ${workspaceInactive && 'opacity-50'}`}
                />
                <div>
                  <Text
                    className={`font-medium ${workspaceInactive && 'text-gray-500'}`}
                  >
                    {t('homePageTabs.chat')}
                  </Text>
                </div>
              </ListItem>
            </DisabledReactRouterLink>
            <DisabledReactRouterLink
              to={ROUTES.HOME.SEARCH.buildPath({
                workspaceId,
              })}
              isDisabled={workspaceInactive}
            >
              <ListItem
                selected={selectedNavigation === 'SEARCH'}
                className="gap-2"
                bordered
                isDisabled={workspaceInactive}
              >
                <FontAwesomeIcon
                  icon={faSearch}
                  className={`text-maia-purple-500 ${workspaceInactive && 'opacity-50'}`}
                />
                <div>
                  <Text
                    className={`font-medium ${workspaceInactive && 'text-gray-500'}`}
                  >
                    {t('homePageTabs.search')}
                  </Text>
                </div>
              </ListItem>
            </DisabledReactRouterLink>
            <DisabledReactRouterLink
              to={ROUTES.HOME.FILE_MANAGER.buildPath({
                workspaceId,
              })}
              isDisabled={workspaceInactive}
            >
              <ListItem
                selected={selectedNavigation === 'FILE_MANAGER'}
                className="gap-2"
                bordered
                isDisabled={workspaceInactive}
              >
                <FontAwesomeIcon
                  icon={faDatabase}
                  className={`text-maia-purple-500 ${workspaceInactive && 'opacity-50'}`}
                />
                <div>
                  <Text
                    className={`font-medium ${workspaceInactive && 'text-gray-500'}`}
                  >
                    {t('homePageTabs.fileManager')}
                  </Text>
                </div>
              </ListItem>
            </DisabledReactRouterLink>
          </AccessControl>
          <Link
            to={ROUTES.HOME.SETTINGS.buildPath({
              workspaceId,
            })}
          >
            <ListItem
              selected={selectedNavigation === 'SETTINGS'}
              className="gap-2"
              bordered
            >
              <FontAwesomeIcon
                icon={faSliders}
                className="text-maia-purple-500"
              />
              <div>
                <Text className="font-medium">
                  {t('homePageTabs.settings')}
                </Text>
              </div>
            </ListItem>
          </Link>
        </div>
        <Separator />
        {children}
      </div>

      {/* ------------------------------------------------ Footer ------------------------------------------------ */}
      <div className="flex flex-col">
        <Separator />
        <div className="flex h-11 items-center justify-between px-4">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.getmaia.ai"
          >
            <LogoLettering size={24} />
          </a>
          <ChangeLanguage />
        </div>
      </div>
    </div>
  );
}

export default MaiaMenuPanel;
