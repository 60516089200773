import { MaiaApiRoutes, MaiaEndpoint } from 'common-ts';
import { useCallback, useEffect, useState } from 'react';

import { SupabaseClient } from '@supabase/supabase-js';
import { captureException } from '@sentry/react';

type RequestError = {
  message: string;
  code?: number;
};

export type ResponseResult<T> =
  | { success: true; data: T; status: number }
  | { success: false; error: RequestError; status: number; data?: T };

export async function fetchApi<
  P extends keyof MaiaApiRoutes,
  E extends keyof MaiaApiRoutes[P],
>(
  supabase: SupabaseClient,
  prefix: P,
  endpoint: E,
  // @ts-ignore
  requestData: MaiaApiRoutes[P][E]['request'],
  bypassAuthenticatoin: boolean = false
  // @ts-ignore
): Promise<ResponseResult<MaiaApiRoutes[P][E]['response']>> {
  try {
    const session = await supabase.auth.getSession();
    const token = session?.data.session?.access_token;
    if (!token && !bypassAuthenticatoin) {
      return {
        success: false,
        status: 401,
        error: { message: 'Could not get access token' },
      };
    }

    const method = (requestData as MaiaEndpoint['request']).method;
    const isBodyAllowed = ['POST', 'PUT'].includes(method);

    const response = await fetch(
      `${import.meta.env.VITE_MAIA_CORE_API_PUBLIC_URL}${prefix}${
        endpoint as string
      }`,
      {
        method,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          ...(bypassAuthenticatoin ? {} : { Authorization: `Bearer ${token}` }),
        },
        ...(isBodyAllowed ? { body: JSON.stringify(requestData) } : {}),
      }
    );

    if (!response.ok) {
      // try to parse error data from response - some endpoints return error data
      let errorData = undefined;
      try {
        errorData = await response.json();
      } catch (error) {
        // ignore error
      }

      return {
        success: false,
        status: response.status,
        data: errorData,
        error: { message: response.statusText, code: response.status },
      };
    }

    return {
      success: true,
      data: await response.json(),
      status: response.status,
    };
  } catch (error) {
    captureException(error);
    console.error(error);
    return {
      success: false,
      status: 499,
      error: { message: `Something went wrong: ${JSON.stringify(error)}` },
    };
  }
}

export function useApi<
  P extends keyof MaiaApiRoutes,
  E extends keyof MaiaApiRoutes[P],
>(
  supabase: SupabaseClient,
  prefix: P,
  endpoint: E,
  // @ts-ignore
  requestData: MaiaApiRoutes[P][E]['request']
) {
  // @ts-ignore
  const [data, setData] = useState<MaiaApiRoutes[P][E]['response'] | undefined>(
    undefined
  );
  const [error, setError] = useState<RequestError | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(
    async (newRequestData?: Partial<typeof requestData>) => {
      setLoading(true);
      const result = await fetchApi(supabase, prefix, endpoint, {
        ...requestData,
        ...newRequestData,
      });
      if (result.success) {
        setData(result.data);
        setError(undefined);
      } else {
        setData(undefined);
        setError(result.error);
      }
      setLoading(false);
    },
    [supabase, prefix, endpoint, requestData]
  );

  useEffect(() => {
    fetchData();
  }, []);

  const refetch = useCallback(
    (newRequestData?: Partial<typeof requestData>) => {
      fetchData(newRequestData);
    },
    [fetchData]
  );

  return { data, error, loading, refetch };
}
