import { BreadcrumbLink, BreadcrumbRoot } from '@/components/ui/breadcrumb';

import { decodeName } from 'common-ts';

type SharepointBreadCrumbsProps = {
  className?: string;
  folders: { id: string; name: string }[];
  onFolderSelect: (index: number) => void;
};

function SharepointBreadCrumbs({
  className,
  folders,
  onFolderSelect,
}: SharepointBreadCrumbsProps) {
  return (
    <BreadcrumbRoot separator={'>'} className={className}>
      {folders.map((folder, index) => {
        return (
          <BreadcrumbLink
            className="cursor-pointer whitespace-nowrap"
            key={`${folder.id}_${index}`}
            as={'div'}
            onClick={() => onFolderSelect(index)}
          >
            {decodeName(folder.name)}
          </BreadcrumbLink>
        );
      })}
    </BreadcrumbRoot>
  );
}

export default SharepointBreadCrumbs;
