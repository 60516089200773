import {
  MaiaApiRoutes,
  STORAGE_SELECTION_MAP_PATH_DELIMITER,
  StorageFileSelectionMap,
  SupportedFileType,
  supportedFileTypes,
  zeros,
} from 'common-ts';
import { useEffect, useRef, useState } from 'react';

import { Checkbox } from '@/components/ui/checkbox';
import FileIcon from '@/components/FileIcon';
import ListItem from '@/components/ListItem';
import { Skeleton } from '@/components/ui/skeleton';
import { Tooltip } from '@/components/ui/tooltip';
import { fetchApi } from '@/utils/useApi';
import { isFileSelectable } from './utils';
import { toaster } from '@/components/ui/toaster';
import { twMerge } from 'tailwind-merge';
import { useBoundStore } from '@/store/useBoundStore';
import { useTranslation } from 'react-i18next';

type SelectableFileSearchResultListProps = {
  className?: string;
  searchTerm: string;
  collectionId: string;
  selectedFileMap: StorageFileSelectionMap;
  onFileCheckboxClick: (fileId: string, filePath: string) => void;
  onSearchResultsLoaded: (
    searchResults: MaiaApiRoutes['/files']['/search']['response']['foundFiles']
  ) => void;
};

function SelectableFileSearchResultList({
  className,
  searchTerm,
  collectionId,
  selectedFileMap,
  onFileCheckboxClick,
  onSearchResultsLoaded,
}: SelectableFileSearchResultListProps) {
  const { t } = useTranslation();

  const workspaceId = useBoundStore((state) => state.workspaceId);
  const supabase = useBoundStore((state) => state.supabase);
  const csv = useBoundStore((state) => state.featureFlags?.csv);

  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<
    MaiaApiRoutes['/files']['/search']['response']['foundFiles']
  >([]);

  const searchTimeOutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    clearTimeout(searchTimeOutRef.current);
    if (searchTerm) {
      setLoading(true);
      searchTimeOutRef.current = setTimeout(async () => {
        const res = await fetchApi(supabase, '/files', '/search', {
          method: 'POST',
          collectionId,
          searchString: searchTerm,
          workspaceId,
        });

        if (!res.success) {
          toaster.create({ type: 'error', title: t('general.tryAgainError') });
          return;
        }

        setFileList(res.data.foundFiles);
        onSearchResultsLoaded(res.data.foundFiles);
        setLoading(false);
      }, 2000);
    }
  }, [searchTerm]);

  return (
    <div
      className={twMerge(
        'flex h-full flex-col gap-2 overflow-y-auto px-4 py-2',
        className
      )}
    >
      {loading
        ? zeros(5).map((_, index) => {
            return (
              <Skeleton key={index}>
                <ListItem selected={false}>Loading</ListItem>
              </Skeleton>
            );
          })
        : fileList.map((file) => {
            const filePath =
              file.path === ''
                ? ''
                : `${STORAGE_SELECTION_MAP_PATH_DELIMITER}${file.path}`;
            const checkedStatus =
              selectedFileMap[collectionId]?.folders[filePath]?.files[
                file.fileId
              ];

            const fileTypeSupported = supportedFileTypes.includes(
              file.mimeType as SupportedFileType
            );

            return (
              <ListItem
                key={file.fileId}
                selected={false}
                className="flex h-10 flex-shrink-0 cursor-pointer items-center gap-2 px-4"
              >
                <Checkbox
                  disabled={
                    !isFileSelectable({
                      csvEnabled: csv,
                      embeddingStatus: file.embeddingStatus,
                      mimeType: file.mimeType,
                    })
                  }
                  colorPalette="maia-purple"
                  className="hover:bg-maia-purple-50 rounded"
                  checked={checkedStatus ?? false}
                  onCheckedChange={() => {
                    onFileCheckboxClick(file.fileId, file.path);
                  }}
                >
                  <Tooltip
                    disabled={file.embeddingStatus === 'FINISHED'}
                    content={
                      !fileTypeSupported
                        ? t('chat.fileSelector.fileTypeNotSupportedTooltip')
                        : file.embeddingStatus === 'FAILED'
                          ? t('chat.fileSelector.fileFailedTooltip')
                          : t('chat.fileSelector.fileNotProcessedTooltip')
                    }
                  >
                    <div className="flex h-10 w-72 items-center gap-2 pl-2">
                      <FileIcon
                        name={file.name}
                        status={file.embeddingStatus}
                        className="text-chakra-gray-400 text-base"
                      />
                      <Tooltip
                        content={
                          <div className="flex flex-col gap-0 font-normal">
                            <span className="text-sm">{file.name}</span>
                            <span className="text-chakra-gray-300 text-xs">
                              {file.path}
                            </span>
                          </div>
                        }
                        openDelay={150}
                        positioning={{ placement: 'top' }}
                      >
                        <div className="flex min-w-0 flex-col gap-0 font-normal">
                          <span className="truncate text-sm">{file.name}</span>
                          <span className="text-chakra-gray-300 truncate text-xs">
                            {file.path}
                          </span>
                        </div>
                      </Tooltip>
                    </div>
                  </Tooltip>
                </Checkbox>
              </ListItem>
            );
          })}
    </div>
  );
}

export default SelectableFileSearchResultList;
