import './i18n';
import './index.css';

import React, { Suspense } from 'react';

import App from './App.js';
import ReactDOM from 'react-dom/client';
import { ChakraContextProvider } from './components/ui/provider';
import { Toaster } from './components/ui/toaster';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ChakraContextProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <App />
        <Toaster />
      </Suspense>
    </ChakraContextProvider>
  </React.StrictMode>
);
