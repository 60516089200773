import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from '../../../components/ui/dialog.js';
import { useEffect, useRef, useState } from 'react';

import { Alert } from '@/components/ui/alert.js';
import { Button } from '../../../components/ui/button.js';
import { Field } from '../../../components/ui/field.js';
import { Input } from '@chakra-ui/react';
import { encodeName } from 'common-ts';
import { useTranslation } from 'react-i18next';

const STRICT_SAFE_CHARACTERS = /[\p{L}\p{N}_-]/gu;

type CreateCollectionModalProps = {
  isOpen: boolean;
  existingFolderNames: string[];
  onClose: () => void;
  onConfirm: (folderName: string) => void;
};

function CreateCollectionModal({
  isOpen,
  existingFolderNames,
  onClose,
  onConfirm,
}: CreateCollectionModalProps) {
  const { t } = useTranslation();

  const folderNameRef = useRef(t('fileManagerPanel.newCollectionName'));
  const [folderNameDuplicateError, setFolderNameDuplicateError] =
    useState(false);
  const [folderNameEmptyError, setFolderNameEmptyError] = useState(false);
  const [folderIllegalNameError, setFolderIllegalNameError] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      folderNameRef.current = '';
      setFolderNameDuplicateError(false);
      setFolderNameEmptyError(false);
      setFolderIllegalNameError(false);
    }
  }, [isOpen]);

  function handleConfirm() {
    if (!STRICT_SAFE_CHARACTERS.test(folderNameRef.current.trim())) {
      setFolderIllegalNameError(true);
      return;
    }

    const { data: encodedName, error: encodeNameError } = encodeName(
      folderNameRef.current.trim()
    );

    if (encodeNameError) {
      setFolderIllegalNameError(true);
      return;
    }

    if (existingFolderNames.includes(encodedName)) {
      setFolderNameDuplicateError(true);
      return;
    }
    if (!encodedName) {
      setFolderNameEmptyError(true);
      return;
    }

    onConfirm(encodedName);
  }

  return (
    <DialogRoot
      open={isOpen}
      onOpenChange={({ open }) => !open && onClose()}
      motionPreset="slide-in-bottom"
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t('fileManagerPanel.createFolderModal.header')}
          </DialogTitle>
        </DialogHeader>{' '}
        <DialogCloseTrigger />
        <DialogBody
          className="flex flex-col gap-4"
          colorPalette={'maia-accent'}
        >
          <Alert
            title={t(
              'fileManagerPanel.createFolderModal.folderTemporaryHintTitle'
            )}
            variant={'subtle'}
            status="info"
          >
            {t('fileManagerPanel.createFolderModal.folderTemporaryHint')}
          </Alert>
          <Field
            invalid={
              folderNameDuplicateError ||
              folderNameEmptyError ||
              folderIllegalNameError
            }
            label={t('fileManagerPanel.createFolderModal.folderNameLabel')}
            errorText={
              folderNameDuplicateError
                ? t(
                    'fileManagerPanel.createFolderModal.folderNameDuplicateError'
                  )
                : folderNameEmptyError
                  ? t('fileManagerPanel.createFolderModal.folderNameEmptyError')
                  : t(
                      'fileManagerPanel.createFolderModal.folderIllegalNameError'
                    )
            }
          >
            <Input
              autoFocus
              onChange={(e) => {
                folderNameRef.current = e.target.value;
                setFolderNameDuplicateError(false);
                setFolderNameEmptyError(false);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleConfirm();
                }
              }}
              defaultValue={folderNameRef.current}
            />
          </Field>
        </DialogBody>
        <DialogFooter className="gap-2">
          <Button className="font-medium" onClick={onClose} variant="outline">
            {t('general.cancelButton')}
          </Button>
          <Button
            className="font-medium"
            colorPalette="maia-accent"
            onClick={handleConfirm}
          >
            {t('fileManagerPanel.createFolderModal.createButton')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}

export default CreateCollectionModal;
