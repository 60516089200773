/**
 * Cleans up unnecessary entries in a FileSelectionMap (SharePoint or storage) object.
 *
 * The 'unnecessary' entries are unnecessary insofar that they do not add any information.
 * They may exist because SelectionMap types were designed for the frontend to achieve a somewhat performant display and update of file / folder selection state.
 * If you have a selection map in the frontend that you want to send to the backend, you should use this function to save bandwidth.
 *
 * The function processes the map and performs two main actions:
 *
 * 1. Removes all folders and their children where `selected === false`. This indicates that
 *    neither the folder nor any of its children should be included.
 *
 * 2. Removes all child entries where `selected === true` for a folder. This implies that
 *    the folder is selected entirely, so its children are implicitly included and thus unnecessary.
 *
 * The function creates a deep clone of the input object before performing the cleanup, and returns this cleaned version.
 *
 * @param selectionMap - The map of selected files and folder that shall be cleaned.
 * @returns {SharepointFileSelectionMap} A new, cleaned file selection map with unnecessary entries removed.
 */
export function cleanUpFileSelectionMap(selectionMap, delimiter) {
    const clonedSelectionMap = JSON.parse(JSON.stringify(selectionMap));
    for (const topLevelId in clonedSelectionMap) {
        if (clonedSelectionMap[topLevelId]?.selected === false) {
            delete clonedSelectionMap[topLevelId]; // Remove entire site / collection if not selected
        }
        else {
            const foldersToDelete = new Set();
            for (const folderId in clonedSelectionMap[topLevelId].folders) {
                const folder = clonedSelectionMap[topLevelId].folders[folderId];
                if (folder?.selected === false) {
                    // Mark folder and its subfolders for deletion
                    foldersToDelete.add(folderId);
                }
                else if (folder?.selected === true) {
                    // Mark all subfolders for deletion, as the folder itself is selected
                    foldersToDelete.add(`${folderId}${delimiter}`);
                    folder.files = {};
                }
            }
            // Delete marked folders and their subfolders
            for (const folderId in clonedSelectionMap[topLevelId].folders) {
                foldersToDelete.forEach((deletePartKey) => {
                    if (folderId.startsWith(deletePartKey)) {
                        delete clonedSelectionMap[topLevelId].folders[folderId];
                    }
                });
            }
        }
    }
    return clonedSelectionMap;
}
