import { MaiaApiRoutes } from 'common-ts';
import {
  createCustomColorTheme,
  defaultSystem,
} from '../../styles/chakraThemeExtension';

type IFrameSettings = MaiaApiRoutes['/iframe']['/:iframe_id']['response'];

/**
 * Adjust a given hex color value by a percentage.
 * @param hexColor - The hex color value to adjust
 * @param percent - The percentage to adjust the color by
 */
export function adjustColor(hexColor: string, percent: number): string {
  // Remove # if present
  hexColor = hexColor.replace('#', '');

  // Convert to RGB
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Adjust each channel
  const adjustChannel = (channel: number): number => {
    const adjusted = channel + (channel * percent) / 100;
    return Math.min(255, Math.max(0, Math.round(adjusted)));
  };

  const nr = adjustChannel(r);
  const ng = adjustChannel(g);
  const nb = adjustChannel(b);

  // Convert back to hex
  return `#${nr.toString(16).padStart(2, '0')}${ng
    .toString(16)
    .padStart(2, '0')}${nb.toString(16).padStart(2, '0')}`;
}

/**
 * Generate color shades from a hex color.
 * Generates shades from 50 to 900. The base color is used for 500.
 * @param hexColor - The base hex color value
 */
export function generateColorTokens(hexColor: string) {
  return {
    'maia-accent': {
      50: { value: adjustColor(hexColor, 85) },
      100: { value: adjustColor(hexColor, 70) },
      200: { value: adjustColor(hexColor, 45) },
      300: { value: adjustColor(hexColor, 20) },
      400: { value: adjustColor(hexColor, 10) },
      500: { value: hexColor },
      600: { value: adjustColor(hexColor, -10) },
      700: { value: adjustColor(hexColor, -20) },
      800: { value: adjustColor(hexColor, -35) },
      900: { value: adjustColor(hexColor, -50) },
    },
  };
}

/**
 * Inject CSS variables into the document root element.
 * @param iframeSettings - the iframe settings which contain the colors to inject.
 */
export function injectCSSVariables(iframeSettings: IFrameSettings) {
  const style = document.createElement('style');

  // We have to build the string one by one to avoid injecting empty variables
  let cssContent = ':root {';

  if (iframeSettings.responseBackgroundColor) {
    cssContent += ` --maia-blue-100: ${iframeSettings.responseBackgroundColor};`;
  }

  if (iframeSettings.primaryBackgroundColor) {
    cssContent += ` --maia-neutral-background: ${iframeSettings.primaryBackgroundColor};`;
  }

  cssContent += ' }';

  style.textContent = cssContent;

  // Remove any existing dynamic style
  const existingStyle = document.getElementById('dynamic-colors');
  if (existingStyle) {
    existingStyle.remove();
  }

  style.id = 'dynamic-colors';
  document.head.appendChild(style);
}

/**
 * Create a dynamic chakra theme based on the iframe settings by extending the base theme.
 * This overrides certain colors in the theme with the colors provided in the iframe settings.
 * @param iframeSettings - the iframe settings which contain the colors override.
 */
export function createDynamicTheme(iframeSettings: IFrameSettings) {
  if (!iframeSettings.primaryColor) return defaultSystem;

  const generatedColorTokens = generateColorTokens(iframeSettings.primaryColor);
  const system = createCustomColorTheme(generatedColorTokens);
  return system;
}
