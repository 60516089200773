import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from '../../../components/ui/dialog';

import { Button } from '../../../components/ui/button';
import { t } from 'i18next';

export type ConformDeleteFileModalProps = {
  isOpen: boolean;
  confirmButtonLoading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const ConfirmDeleteFileModal = ({
  isOpen,
  confirmButtonLoading = false,
  onClose,
  onConfirm,
}: ConformDeleteFileModalProps) => (
  <DialogRoot open={isOpen} onOpenChange={({ open }) => !open && onClose()}>
    <DialogBackdrop />
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{t('deleteFileModal.title')}</DialogTitle>
      </DialogHeader>
      <DialogBody>{t('deleteFileModal.confirmText')}</DialogBody>
      <DialogFooter>
        <div className="flex items-center gap-2">
          <Button
            className="text-maia-text-dark font-medium"
            onClick={onClose}
            variant="outline"
          >
            {t('general.cancelButton')}
          </Button>
          <Button
            className="bg-maia-support-red text-maia-text-light font-medium"
            onClick={onConfirm}
            loading={confirmButtonLoading}
          >
            {t('general.deleteButton')}
          </Button>
        </div>
      </DialogFooter>
    </DialogContent>
  </DialogRoot>
);
